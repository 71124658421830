import {mapGetters} from "vuex";

export default {
  data() {
    return {
      completedCaptchaToken: null,
      widgetId: undefined,
    };
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings/settings',
    }),
    SMART_CAPTCHA_KEY() {
      return this.settings.smart_captcha_client_key;
    }
  },
  created() {
    if (process.server) return;

    if (!window.smartCaptcha || !this.SMART_CAPTCHA_KEY) {
      console.error(`Капча не подключена. window.smartCaptcha = ${window.smartCaptcha}, SMART_CAPTCHA_KEY = ${this.SMART_CAPTCHA_KEY}`);
      return;
    }
  },
  methods: {
    render(idContainer = 'captcha-container', extraCallback) {
      if (!window.smartCaptcha || !this.SMART_CAPTCHA_KEY) return;

      try {
        this.widgetId = window.smartCaptcha.render(idContainer, {
          sitekey: this.SMART_CAPTCHA_KEY,
          invisible: true,
          hl: 'ru',
          test: false,
          hideShield: true,
          callback: (token) => {
            this.completedCaptchaToken = token;
            if (extraCallback) extraCallback();
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    destroy() {
      try {
        this.completedCaptchaToken = null;
        window.smartCaptcha.destroy(this.widgetId);
      } catch (e) {
        console.error(e);
      }
    },
    execute() {
      try {
        window.smartCaptcha.execute(this.widgetId);
      } catch (e) {
        console.error(e);
      }
    },
  }
};
